<template>
  <div id="descubre" class="w-full mx-auto flex">
    <div class="descubre-img">
      <img
          src="@/assets/img/descubre-trk.jpg"
          alt="Descubre TRK Golf"
          class=""
      />
    </div>
    <div class="descubre-txt">
      <h1 style="text-align: left!important">{{ $t('home.descubre_trk_title') }}</h1>
      <p v-html="$t('home.descubre_trk_body')"></p>
    </div>
  </div>
  <div id="como-funciona" class="w-full mx-auto" :style="{ backgroundImage: `url(${require('@/assets/img/fondo-letras.svg')})` }">
    <div class="container mx-auto px-6 lg:px-4 flex justify-between">
      <div class="funciona-txt">
        <h2>{{ $t('home.como_funciona_title') }}</h2>
        <p v-html="$t('home.como_funciona_body')"></p>
      </div>
      <div class="funciona-img container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/p5Od2TGezg8?si=Fmm_tKsDY2xNlyrW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="video"></iframe>
      </div>
    </div>
  </div>
  <div id="que-ofrece" class="container mx-auto px-6 lg:px-4 ">
    <div>
      <h2>{{ $t('home.que_ofrece_title') }}</h2>
    </div>
    <div class="flex justify-between caja-ofrece">
      <div>
        <img src="@/assets/img/ofrece1.png" alt="Que ofrece TRK">
        <p v-html="$t('home.que_ofrece_body_1')"></p>
      </div>
      <div>
        <img src="@/assets/img/ofrece2.png" alt="Que ofrece TRK">
        <p v-html="$t('home.que_ofrece_body_2')"></p>
      </div>
      <div>
        <img src="@/assets/img/ofrece3.png" alt="Que ofrece TRK">
        <p v-html="$t('home.que_ofrece_body_3')"></p>
      </div>
    </div>
  </div>
  <div id="proximamente" class="w-full mx-auto">
    <div class="container mx-auto px-6 lg:px-4">
      <div>
        <h2 class="text-verde">{{ $t('home.proximamente_title') }}</h2>
      </div>
      <div class="flex justify-between flex-wrap lg:gap-9 gap-6 caja-proximamente">
        <div>
          <img src="@/assets/img/proximamente-app.png" alt="App">
          <h3 class="uppercase">{{ $t('home.proximamente_title_1') }}</h3>
          <p v-html="$t('home.proximamente_body_1')"></p>
        </div>
        <div>
          <img src="@/assets/img/proximamente-comparativa.png" alt="Comparativa por hándicap">
          <h3 class="uppercase">{{ $t('home.proximamente_title_2') }}</h3>
          <p v-html="$t('home.proximamente_body_2')"></p>
        </div>
        <div>
          <img src="@/assets/img/proximamente-profesionales.png" alt="PROFESIONALES">
          <h3 class="uppercase">{{ $t('home.proximamente_title_3') }}</h3>
          <p v-html="$t('home.proximamente_body_3')"></p>
        </div>
        <div>
          <img src="@/assets/img/proximamente-rankings.png" alt="RANKINGS">
          <h3 class="uppercase">{{ $t('home.proximamente_title_4') }}</h3>
          <p v-html="$t('home.proximamente_body_4')"></p>
        </div>
        <div>
          <img src="@/assets/img/proximamente-compa-comunidad.png" alt="COMPARATIVA COMUNIDAD">
          <h3>{{ $t('home.proximamente_title_5') }}</h3>
            <p v-html="$t('home.proximamente_body_5')"></p>
        </div>
        <div>
          <img src="@/assets/img/proximamente-nuevas.png" alt="NUEVAS ESTADÍSTICAS">
          <h3 class="uppercase">{{ $t('home.proximamente_title_6') }}</h3>
          <p v-html="$t('home.proximamente_body_6')"></p>
        </div>
      </div>
    </div>    
  </div>
</template>

<script setup>

  import { usePopupStore } from "@/store/popup";  
  import { useRoute } from 'vue-router';

  const url = useRoute();
  const mostrarModal = url.query.infomodal;
  
  if (mostrarModal === 'ok') {
    usePopupStore().toggleInfo(true)
  }

</script>

<style scoped>
  #descubre{
    padding-top: 130px;
    padding-bottom: 130px;
    @apply text-texto;
  }

  #como-funciona{
    padding-top: 95px;
    padding-bottom: 95px;
    @apply bg-verde text-white;
  }

  #que-ofrece{
    padding-top: 95px;
    padding-bottom: 95px;
    @apply text-texto;
  }

  #proximamente{
    padding-top: 50px;
    padding-bottom: 120px;
    @apply bg-verde-claro bg-opacity-30 text-white;
  }
  
  .descubre-img{
    width: 45%;
  }

  .descubre-txt{
    width: 55%;
    padding: 0px 45px;
  }

  .descubre-txt h1, 
  .funciona-txt h2, 
  #que-ofrece h2,
  #proximamente h2{
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1.6px;
    margin-bottom: 20px;
  }

  .descubre-txt p{    
    max-width: 536px;
  }

  .descubre-txt p, 
  .funciona-txt p,
  .caja-ofrece p,
  .caja-proximamente p
  {
    font-size: 19px;
    line-height: 29px;
    margin-bottom: 20px;        
    font-weight: 300;
    letter-spacing: 0.76px;
  }

  .descubre-txt p b, .funciona-txt p b, .caja-ofrece p b{     
    font-weight: bold;
  }

  .funciona-txt{
    max-width: 377px;
  }


  .caja-ofrece img{
    max-height: 97px;
    width: auto;
    margin-bottom: 25px;
  }

  .caja-ofrece>div{
    max-width: 255px;
  }
  
  .caja-proximamente{
    margin-top: 25px;
  }
  .caja-proximamente>div{
    flex-basis: calc(33.33% - 25px);
    border-radius: 19px;
    padding: 20px;
    @apply bg-verde-claro;
  }

  .caja-proximamente img{
    margin: 0 0 30px auto;
    max-height: 65px;
    width: auto;
  }

  .caja-proximamente h3{
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    margin-bottom: 10px;

  }

  iframe[src*="youtube.com"] {
    width:  100%;
    height: auto;
    aspect-ratio:16/9;
  }

  .funciona-img{
    padding-left: 25px;
  }

  @media only screen and (max-width: 1024px) {

    #descubre {
      flex-direction: column-reverse;
      padding-top: 55px;
      padding-bottom: 0;
    }

    #que-ofrece{
      padding-top: 55px;
      padding-bottom: 55px;
    }

    .descubre-img, .descubre-txt {
      flex: none;
      width: 100%; 
    }
    .funciona-img{
    padding-left: 0px;
  }

    .descubre-txt {
      padding: 0px 1.5rem 50px;
    }

    .descubre-txt h1, 
    .funciona-txt h2, 
    #que-ofrece h2,
    #proximamente h2,
    .caja-proximamente h3{
      font-size: 22px;
    }


    .descubre-txt p, 
    .funciona-txt p,
    .caja-ofrece p,
    .caja-proximamente p
    {
      font-size: 15px;
      line-height: 21.9px;
    }

    .caja-proximamente>div{
      flex-basis: calc(50% - 25px);
    }

    #como-funciona>div{
      flex-direction: column;
    }

    .funciona-txt{
      width: 100%;
      max-width: none;
    }

    /* .funciona-img{
      width: 100%;
      max-width: none;
    } */


  }

  @media only screen and (max-width: 768px) {

    #como-funciona {
      padding-top: 55px;
    }

    #proximamente{
      padding-bottom: 55px;
    }



    .caja-proximamente>div{
      flex-basis: calc(100%);
    }


    .caja-ofrece{
      flex-direction: column;
    }

    .caja-ofrece>div{
      width: 100%;
      max-width: none;
      margin-bottom: 25px;
    }

    .caja-ofrece>div:last-child{
      margin-bottom: 0px;
    }

  }

 


</style>
 