import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n';
import { createPinia } from 'pinia'
import './assets/comunidad.css'
import './assets/popup.css'
import './assets/tailwind.css'
import { install } from "vue3-recaptcha-v2";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

createApp(App).use(ToastPlugin).use(createPinia()).use(router).use(i18n).use(install, {
    sitekey: "6Le70DYqAAAAAGguO4j16ou928dnbn3mEXw92-kr",
    cnDomains: false, // Optional, If you use in China, set this value true
  }).component('QuillEditor', QuillEditor).mount('#app')