// src/i18n.js
import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
    const locales = require.context('@/locales', true, /\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const locale = key.match(/([A-Za-z0-9-_]+)\./i)[1]
        messages[locale] = locales(key)
    })
    return messages
}

const defaultLocale = ( localStorage.getItem('locale') != null) ? localStorage.getItem('locale') : 'es';
localStorage.setItem('locale', defaultLocale);

const i18n = createI18n({
    legacy: false,
    locale: defaultLocale, // idioma por defecto
    globalInjection: true,
    escapeParameterHtml: false,
    fallbackLocale: 'en', // idioma alternativo si no se encuentra una traducción
    messages: loadLocaleMessages(),
});

export default i18n;