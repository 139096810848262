<template>
    <header class="bg-gray-900 lg:pt-14 pt-8 fondo-header">
        <div class="container mx-auto lg:px-4 px-6 pb-8">
            <div class="flex justify-between items-start">
                <div class="cont-logo">
                    <router-link to="/" @click="closeMenu">
                        <img src="@/assets/img/logo.png" alt="TRF" class="w-auto" :class="{'lg:h-24 h-20':!isHomePage, 'lg:h-36 h-20':isHomePage}" />
                    </router-link>
                </div>
    
                <div class="lg:hidden flex">
                    <router-link to="/perfil" class="z-10 pr-5" v-if="isUserLoggedIn">
                        <img v-if="userData.thumbnail" :src="ulr + userData.thumbnail" alt="Foto de perfil" class="h-7 w-7 rounded-full" />
                        <img v-else-if="thumbnail" :src="ulr + thumbnail" alt="Foto de perfil" class="h-7 w-7 rounded-full" />
                        <img v-else alt="Foto" class="h-7 w-7 rounded-full" />
                    </router-link>
                    
                    <div ref="menuRef" class="pr-2 relative inline-block">
                        <!-- Botón principal con la flecha -->
                        <a 
                          href="#" 
                          class="language flex items-center gap-2 cursor-pointer text-gray-800 hover:text-black uppercase"
                          @click="handleClick"
                        >
                          {{ locale }}
                          <span class="transition-transform duration-300">▼</span>
                        </a>
                    
                        <!-- Lista desplegable de idiomas -->
                        <ul
                          v-if="showMenu"
                          class="languageUl absolute left-0 top-full mt-1 w-24 rounded-lg transition-opacity duration-300"
                          style="z-index: 999999999"
                        >
                        <li 
                            v-if="locale !== 'es'" 
                            @click="changeLanguage('es')" 
                            class="pt-2 cursor-pointer"
                        >
                            <a href="#" class="block w-full text-white">ES</a>
                        </li>
                        <li 
                            v-if="locale !== 'en'" 
                            @click="changeLanguage('en')" 
                            class="pt-2 cursor-pointer"
                        >
                            <a href="#" class="block w-full text-white">EN</a>
                        </li>
                        </ul>
                    </div>

                    <img @click="toggleMobileMenu" src="@/assets/img/hamburguer.png" alt="Menú" class="h-6 cursor-pointer toggle-menu ml-3" />
                </div>
    
                <nav class="space-x-6 items-center" :class="{ 'hidden lg:flex': !isMobileMenuOpen, 'nav-movil': isMobileMenuOpen }"> 
                    <router-link @click="closeMenu" to="/">{{ $t('inicio') }}</router-link>
                    <router-link @click="closeMenu" to="/tienda">{{ $t('tienda') }}</router-link>
    
                    <template v-if="!isUserLoggedIn">
                    <router-link @click="closeMenu" to="/estadisticas-t">{{ $t('estadisticas') }}</router-link>
                    <router-link @click="closeMenu" to="/vueltas-t">{{ $t('vueltas') }}</router-link>
                    <router-link @click="closeMenu" to="/comunidad-t">{{ $t('comunidad') }}</router-link>
                    <div class="link-registra-vuelta-logged"  @click="() => { closeMenu(); goTo('/registrar-vuelta-t'); }">{{ $t('registrar') }}</div>
                    <a @click="() => { closeMenu(); openPopUp(); }" class="link-login">{{ $t('iniciar_sesion') }}</a>
                    </template>

                    <template v-else>
                        <router-link @click="closeMenu" to="/estadisticas">
                            {{ $t('estadisticas') }}
                        </router-link>
                        <router-link @click="closeMenu" :to="{ name: 'historico-vueltas', params: { id: undefined } }">{{ $t('vueltas') }}</router-link>
                        <router-link @click="closeMenu" to="/comunidad">{{ $t('comunidad') }}</router-link>
                        <div class="link-registra-vuelta-logged" @click="() => { closeMenu(); combrobarVueltasDispo(); }">{{ $t('registrar') }}</div>
                        
                        <div @click="() => { closeMenu(); toggleVerMas(); }" class="ver-mas cursor-pointer hidden lg:flex">
                            <img v-if="userData.thumbnail" :src="ulr + userData.thumbnail" alt="Foto de perfil" class="h-10 w-10 rounded-full" />
                            <img v-else-if="thumbnail" :src="ulr + thumbnail" alt="Foto de perfil" class="h-10 w-10 rounded-full" />
                            <img v-else alt="Foto" class="h-10 w-10 rounded-full" />
                        </div>
                        
                        <div @click="() => { closeMenu(); toggleVerMas(); }" class="ver-mas cursor-pointer hidden lg:flex">
                            <span class="text-white" v-if="userData.name">{{userData.name}}</span>
                            <span class="text-white" v-else>{{name}}</span>
                            <div :class="{ 'hidden': !isVerMasOpen }">
                                <router-link @click="closeMenu" to="/perfil">{{ $t('ver_perfil') }}</router-link>
                                <span @click="auth.logout()"><router-link to="/">{{ $t('cerrar_sesion') }}</router-link></span>
                            </div>
                        </div>
                        
                        <div class="lg:hidden flex">
                            <router-link @click="closeMenu" to="/perfil">Ver Perfil</router-link>
                        </div>
                        <div class="lg:hidden flex">
                            <span @click="auth.logout()"><router-link to="/">Cerrar Sesion</router-link></span>
                        </div>
                    </template>
                    <!-- Botón principal con la flecha -->
                    <div class="relative inline-block hidden md:inline-block">
                        <!-- Botón principal con la flecha -->
                        <a 
                          href="#" 
                          class="language flex items-center gap-2 cursor-pointer text-gray-800 hover:text-black uppercase"
                          @mouseenter="showMenu = true"
                        >
                          {{ locale }}
                          <span class="transition-transform duration-300">▼</span>
                        </a>
                    
                        <!-- Lista desplegable de idiomas -->
                        <ul
                          v-if="showMenu"
                          class="languageUl absolute left-0 top-full mt-1 w-24 rounded-lg transition-opacity duration-300"
                          style="z-index: 999999999"
                          @mouseenter="showMenu = true"
                          @mouseleave="showMenu = false"
                        >
                        <li 
                            v-if="locale !== 'es'" 
                            @click="changeLanguage('es')" 
                            class="pt-2 cursor-pointer"
                        >
                            <a href="#" class="block w-full text-white">ES</a>
                        </li>
                        <li 
                            v-if="locale !== 'en'" 
                            @click="changeLanguage('en')" 
                            class="pt-2 cursor-pointer"
                        >
                            <a href="#" class="block w-full text-white">EN</a>
                        </li>
                        </ul>
                    </div>
                </nav>
      </div>
    </div>
    <div v-if="isHomePage" class="caja-cita container mx-auto lg:px-4 px-6">
      <div class="cita">
        "Golf is the only game I know of that actually becomes harder the longer you play it."
      </div>
      <div class="autor-cita">
        BOBBY JONES
      </div>
    </div>
  </header>
  

</template>
 
<!-- Grabar -->
<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";
import { useAuthStore } from '@/store/auth.store';
import { storeToRefs } from "pinia";
import { useUser } from '@/store/user.store';
import router from '@/router';
import { useI18n } from 'vue-i18n';

const showMenu  = ref(false); // Estado para mostrar u ocultar el menú
const menuRef   = ref(false);

const { locale } = useI18n();

/***************/
const changeLanguage = (lang) => {
    locale.value = lang; // Cambia el idioma a español
    localStorage.setItem('locale', lang);
    showMenu.value = false;
};
/***************/

const msg = usePopupStore();
const { mensaje } = storeToRefs(msg);

const request = useUser();

const ulr = process.env.VUE_APP_BASE

function combrobarVueltasDispo() {

    const { ok } = storeToRefs(request);

    request.hasAvailableLaps().then(() => {
        if (ok.value) {
            router.push('/registrar-vuelta');
        }
    }).catch(function(error) {
        const errorObject = JSON.parse(error)
        console.log(errorObject)
        mensaje.value = errorObject.message
        if (route.path == "/") {
            msg.toggleInfo(true);
        } else {
            router.push('/?infomodal=ok');
        }
    })
}

function goTo(route) {
    router.push(route);
}

// Propiedad computada para determinar si estamos en la página de inicio o no
const route = useRoute();
const isHomePage = computed(() => route.path === "/");

const auth = useAuthStore();
const { isUserLoggedIn, userData } = storeToRefs(auth);
const userdata = localStorage.getItem("userData")
let name
const thumbnail = ref("")
if (userdata) {
    const userDataParse = JSON.parse(userdata)
    if (userDataParse.name != null) {
        name = userDataParse.name
    }
    if (userDataParse.thumbnail != null) {
        thumbnail.value = userDataParse.thumbnail
    }
}


// Toggle mostrar ocultar menu movil
const isMobileMenuOpen = ref(false);
const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value
};

const openPopUp = () => {
    usePopupStore().togglePopup(true)
}

const isVerMasOpen = ref(false);
const toggleVerMas = () => {
    isVerMasOpen.value = !isVerMasOpen.value;
};

const closeMenu = () => {
    isMobileMenuOpen.value = false;
};

// Función para detectar clics fuera del menú
const handleClickOutside = (event) => {
  if (menuRef.value && !menuRef.value.contains(event.target)) {
    showMenu.value = false;
  }
};

const handleClick = () => {
    showMenu.value = !showMenu.value;
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside, true);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside, true);
});
</script>

<style scoped>
.ver-mas>div {
    position: absolute;
    top: 60px;
    width: 175px;
    right: 0px;
}

.ver-mas a {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 420;
    line-height: normal;
    border: 1px solid;
    padding: 10px 15px;
    margin-bottom: 10px;
    @apply rounded text-gris w-full block;
    background-color: white;
}

a.language, a.language:hover, a.language:visited, a.language:focus {
    color: #FFF
}
</style>
  