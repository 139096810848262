import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { manageErrors } from '@/helpers/errors';

const baseUrl               = process.env.VUE_APP_API_BASE + "/comunidad" ;

export const useAmigosStore = defineStore({
    id: 'friend',
    state: () => ({

        notificaciones: false,
        friends:[],
        friendsRequest: [],
        q:"",
        page:1,
        perPage:9,
        filteredFriendsList:[],
        listResultSearch:[],
        message:"",
        ok:false,
        q_friends:"",
        solicitudLength:0,
        totalItems: 0,

    }),
    actions: {

        async misAmigos(){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/getFriends').then( function(response) {
                objeto.friends = response.data;
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async requestAmigos(){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/getFriendsRequests' ).then( function(response) {
                objeto.friendsRequest = response.data;
                objeto.solicitudLength = response.data.length;
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async acceptRequestFriend( id ){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/acceptFriendRequest/'+ id ).then( function(response) {
                objeto.message = response.message
                objeto.ok = response.ok
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async buscarNuevoAmigo(){
            const params ={
                page: this.page,
                perPage: this.perPage, 
                q: this.q, 
            }     
            this.listResultSearch = []
            let objeto = this;
            this.misAmigos()
            await fetchWrapper.post(`${baseUrl}` + '/search',  params  ).then( function (response) {
                let resultadosConAmigos = response.data.data.map(objetoActual => {
                let amigoEncontrado = objeto.friends.find(friend => friend._id === objetoActual._id);
                    return {
                        ...objetoActual,  // Mantiene los datos originales
                        amigoMio: amigoEncontrado ? true : false
                    };
                });                
                // Almacena los resultados modificados en self.listResultSearch
                objeto.listResultSearch = resultadosConAmigos;
                objeto.totalItems = response.data.total;
            }).catch( function(error){ manageErrors( error ) });
        },

        async sendFriendRequest( f_id ){
            let objeto = this;
            await fetchWrapper.post(`${baseUrl}` + '/sendFriendRequest', {f_id} ).then( function(response) {
                objeto.message  = response.message
                objeto.ok       = response.ok
            }).catch( function(error){ manageErrors( error ) });
        },

        async deleteFriendAction(id){
            let objeto = this;
            await fetchWrapper.post(`${baseUrl}` + '/deleteFriend/'+ id ).then( function(response) {
                objeto.message = response.message
                objeto.ok = response.ok
                objeto.misAmigos();
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async filteredFriends(){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/getFriends').then( function(response) {
                const serchQuerry = objeto.q_friends.toLowerCase();
                objeto.filteredFriendsList = response.data.filter(friend => {
                    return (
                        friend.user.toLowerCase().includes(serchQuerry) ||
                        friend.surname.toLowerCase().includes(serchQuerry) ||
                        friend.name.toLowerCase().includes(serchQuerry)
                    );
                })
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async cancelRequestFriend(id) {
            let objeto = this;
            await fetchWrapper.post(`${baseUrl}` + '/declineFriendRequest/'+ id ).then( function(response) {
                objeto.message = response.message
                objeto.ok = response.ok   
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async filteredFriendsPopStats(){
            let objeto = this;
            await fetchWrapper.post(`${baseUrl}` + '/getFriends').then( function(response) {
                const serchQuerry = objeto.q_friends.toLowerCase();
                objeto.filteredFriendsList = response.data.filter(friend => {
                    return (
                        friend.user.toLowerCase().includes(serchQuerry) ||
                        friend.surname.toLowerCase().includes(serchQuerry) ||
                        friend.name.toLowerCase().includes(serchQuerry)
                    );
                })
            }).catch( function(error){ manageErrors( error ) });            
        },


    }

});