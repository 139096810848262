import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { manageErrors } from '@/helpers/errors';
import { useAuthStore } from '@/store/auth.store';
import i18n from '@/i18n'; 
import axios from 'axios';

const { t }                     = i18n.global;
const baseUrl               = process.env.VUE_APP_API_BASE + "/user" ;
const updateUser            = process.env.VUE_APP_API_BASE + '/updateUser';
const subs                  = process.env.VUE_APP_API_BASE + "/tpv"

export const useUser = defineStore({
    id: 'user',
    state: () => ({
        ok:"",
        message:"",
        mysubsdata:{},
        cancelled: false
    }),
    actions: {
        async share(emails) {
            const { user } = useAuthStore();
            const isLoggedIn = !!user?.access_token; 
            if (!isLoggedIn) { 
                return Promise.reject({ message: t('noAuth') }); 
            } 
        
            try {
                const response = await axios.post(`${baseUrl}/share`, 
                    { emails }, 
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                return response;    
            } catch (error) {
                // Devolver el error correctamente
                return Promise.reject(error.response.data.message || { message: "Error desconocido" });
            }
        },

        async hasAvailableLaps(){
            let objeto = this
            await fetchWrapper.get(`${baseUrl}` + '/hasAvailableLaps').then( function(response) {
                objeto.ok           = response.ok;
                objeto.messageError = response.message;
            }).catch( function(error){ manageErrors( error ) });
        },

        async updateUser(data){
            let objeto = this
            await fetchWrapper.post( updateUser, data ).then( function(response) {
                objeto.ok       = response.ok
                objeto.message  = response.message
            }).catch( function(error){ manageErrors( error ) });
        },
        async updateImge(data){
            let objeto = this
            await fetchWrapper.postForm( `${baseUrl}` + '/uploadImage'  , data ).then( function(response) {                
                objeto.ok       = response.ok
                objeto.message  = response.message
            }).catch( function(error){ manageErrors( error ) });
        },
        async mysub(){
            let objeto = this
            await fetchWrapper.get( `${subs}` + '/getSubscription' ).then( function(response) {   
                objeto.mysubsdata     = response.data             
                objeto.ok             = response.ok
                objeto.message        = response.message
                objeto.cancelled        = response.cancelled
            }).catch( function(error){ manageErrors( error ) });
        }

    }
});